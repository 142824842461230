import React from 'react'
import "./Footer.css"

function Footer() {
  return (
    <div className ="footer"><p>© 2023 Khojo! All rights are reserved to Khojo</p>
    {/* <link>Privacy</link> */}
    <p>Privacy · Terms · Sitemap · Company Details</p>
    </div>
  )
}

export default Footer