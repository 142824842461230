import React, { Fragment } from 'react'

const SearchPage = () => {
  return (
    <Fragment>
      This is SearcPage.js Not Completed Yet!
    </Fragment>
  )
}

export default SearchPage